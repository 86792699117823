// 输入框只能以字母或中文开头，只允许输入中文、英文、数字及特殊符号，并且最大输入长度为32位
export function validatePass(rule, value, callback) {
    let re = /^([\u4E00-\uFA29]|[\uE7C7-\uE7F3]|[a-zA-Z])/, r
    r = re.test(value)
    if (value.length > 0 && !r) {
        callback(new Error('请输入中文，英文，数字及特殊字符，必须以中文或英文开头！'))
        return
    }
    if (value.length > 32) {
        callback(new Error('输入最大长度为32位！'))
        return
    }
    callback()
}

// 输入框只允许输入英文、数字及特殊符号，并且最大输入长度为32位
export function validateRules(rule, value, callback) {
    let re = /([\u4E00-\uFA29]|[\uE7C7-\uE7F3])/g, r
    r = re.test(value)
    if (value.length > 0 && r) {
        callback(new Error('请输入英文，数字及特殊字符！'))
        return
    }
    if (value.length > 32) {
        callback(new Error('输入最大长度为32位！'))
        return
    }
    callback()
}

// 输入框只允许输入英文、数字及特殊符号，必须以英文字母开头，且最大输入长度为32位
export function validateEn(rule, value, callback) {
    let re = /^[a-zA-Z][\d\sa-zA-Z]*$/, r
    r = re.test(value)
    if (value.length > 0 && !r) {
        callback(new Error('请输入英文，数字及特殊字符，必须以英文字母开头！'))
        return
    }
    if (value.length > 32) {
        callback(new Error('输入最大长度为32位！'))
        return
    }
    callback()
}

// 输入框只允许输入数字和小数点
export function validateNum(rule, value, callback) {
    let re = /^\d+$|^\d*\.\d+$/g, r
    r = re.test(value)
    if (value.length > 0 && !r) {
        callback(new Error('只能输入数字！'))
        return
    }
    callback()
}

// 输入框只允许输入正整数
export function validateInt(rule, value, callback) {
    let re = /^[0-9]*[1-9][0-9]*$/, r
    r = re.test(value)
    if (value.length > 0 && !r) {
        callback(new Error('只能输入正整数！'))
        return
    }
    callback()
}

// 手机号验证
export function phoneCheck (rule, value, callback) {
    const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
    if (!reg.test(value)) {
        callback('请输入有效的手机号！')
        return
    }
    callback()
}
