<template>
    <div>
        <Header />
        <div class="projectWrap">
            <div class="projectTop">
                <p class="projectTitle">
                    项目概况
                </p>
                <a-button type="primary" @click="gotoHome">
                    返回主页
                </a-button>
            </div>
            <ul class="projectStatic">
                <li>
                    <p class="projectType">
                        我的项目
                    </p>
                    <p class="projectNum">
                        {{ dataObj.myProject }}
                    </p>
                </li>
                <li>
                    <p class="projectType">
                        授权项目
                    </p>
                    <p class="projectNum">
                        {{ dataObj.authorizeProject }}
                    </p>
                </li>
                <li>
                    <p class="projectType">
                        设备总数
                    </p>
                    <p class="projectNum">
                        {{ dataObj.equipCount }}
                    </p>
                </li>
            </ul>
            <div class="card-container">
                <a-tabs type="card" :default-active-key="defaultKey">
                    <a-tab-pane key="1" tab="我的项目">
                        <div class="search">
                            <a-input-search style="width: 400px" :allow-clear="true" placeholder="请输入项目名称搜索" enter-button @search="onSearch" />
                            <a-button type="primary" @click="handleProject('create')">
                                创建项目
                            </a-button>
                        </div>
                        <a-table style="margin-bottom: 20px" :locale="{emptyText: '暂无数据'}" :columns="columns" :pagination="false" :data-source="data">
                            <template slot="operation" slot-scope="text, record">
                                <a href="javascript:void(0);" @click="entryManage(record)">进入项目管理</a>
                                <a href="javascript:void(0);" style="margin: 0 20px" @click="authorizeManage(record)">授权管理</a>
                                <a href="javascript:void(0);" @click="handleProject('edit')">编辑</a>
                                <a href="javascript:void(0);" style="margin-left: 20px" @click="onDelete(record)">删除</a>
                            </template>
                        </a-table>
                    </a-tab-pane>
                    <a-tab-pane key="2" tab="授权项目">
                        <div class="authorizeItem">
                            <a-input-search style="width: 400px" placeholder="请输入项目名称搜索" enter-button @search="onSearchAuthorize" />
                            <p class="authorizeTip">
                                当前还没有授权项目
                            </p>
                        </div>
                    </a-tab-pane>
                </a-tabs>
            </div>
        </div>
        <!-- 创建、编辑项目弹窗 -->
        <a-modal v-model="isVisible" :width="800" :title="title" cancel-text="取消" ok-text="确认" :centered="true" @ok="onCreate">
            <ul style="display: flex; justify-content: space-between">
                <li>
                    <a-form :form="projectForm">
                        <a-form-item label="项目名称" style="display: flex">
                            <a-input
                                v-model="projectForm.name"
                                placeholder="请输入项目名称"
                                style="width: 200px" />
                        </a-form-item>
                        <a-form-item label="描述">
                            <a-textarea
                                v-model="projectForm.desc"
                                style="width: 300px" />
                        </a-form-item>
                    </a-form>
                </li>
                <li style="flex:1; margin-left: 40px">
                    <Map :site="site" @onSearch="siteInfo" @onChange="clearSite" />
                </li>
            </ul>
        </a-modal>
        <!-- 授权管理弹窗 -->
        <a-modal v-model="visible" :width="800" title="授权管理" :centered="true" :closable="false">
            <p class="modelTip">
                可以通过手机号向其他账户授权浏览项目信息（不可进行修改，无法查看key等敏感信息），每个项目最多授权10人
            </p>
            <a-form-model ref="ruleForm" :model="form" :rules="rules" layout="inline">
                <a-form-model-item label="新增授权" prop="phone">
                    <a-input
                        v-model="form.phone"
                        :allow-clear="true"
                        placeholder="请输入子账号对应的手机号"
                        style="width: 400px" />
                </a-form-model-item>
                <a-form-model-item>
                    <a-button type="primary" @click="handleAuthorize">
                        授权
                    </a-button>
                </a-form-model-item>
            </a-form-model>
            <p style="margin: 20px 0 10px">
                已授权用户列表
            </p>
            <a-table :columns="authorizeColumns" :locale="{emptyText: '暂无数据'}" row-key="phone" :pagination="false" :data-source="authorizeData">
                <template slot="operation" slot-scope="text, record">
                    <a href="javascript:void(0);" @click="cancelAuthorize(record)">取消授权</a>
                </template>
            </a-table>
            <template slot="footer">
                <a-button key="close" @click="visible = false">
                    关闭
                </a-button>
            </template>
        </a-modal>
        <!-- 新增授权弹窗 -->
        <a-modal
            title="确认授权项目给用户？"
            :visible="visibleAddAuthorize"
            ok-text="确认"
            cancel-text="取消"
            :centered="true"
            @cancel="visibleAddAuthorize = false"
            @ok="handleModal">
            <p>用户名：***** 手机号:1389611xxxx</p>
            <p>
                授权项目后，当前用户可在我的项目的项目概况页点击授权管理进行授权用户的管理。 同时项目授权后，当前用户仍然拥有对相关设备的管理和查看权限，目标用户对项目的项目概括、 产品列表、项目列表只有查看权限，不能进行管理操作。请确认当前转移申请信息填写无误， 同时确保项目授权过程中的信息保密
            </p>
        </a-modal>
        <!-- 取消授权弹窗 -->
        <a-modal
            title="确认取消授权项目给用户？"
            :visible="visibleCancelAuthorize"
            ok-text="确认"
            cancel-text="取消"
            :centered="true"
            @cancel="visibleCancelAuthorize = false"
            @ok="handleCancelAuthorize">
            <p>用户名：***** 手机号:1389611xxxx</p>
            <p>
                取消授权后，目标用户将无法拥有对项目的项目概括、 产品列表以及相关设备的查看权限，不能进行管理操作。请确认当前取消授权信息无误，  并点击下方确认按钮取消授权
            </p>
        </a-modal>
    </div>
</template>

<script>
import Header from "@/views/Header"
import Map from "@/components/Map"
// import { getJson } from "@/api/demo"
import { phoneCheck } from "@/utils/validate"
const columns = [
    {
        title: '项目名称',
        dataIndex: 'name',
        key: 'name'
    },
    {
        title: '项目ID',
        dataIndex: 'key',
        key: 'key'
    },
    {
        title: '创建时间',
        dataIndex: 'time',
        key: 'time'
    },
    {
        title: '描述',
        dataIndex: 'desc',
        key: 'desc'
    },
    {
        title: '操作',
        width: '320px',
        dataIndex: 'handle',
        scopedSlots: { customRender: 'operation' }
    },
]
const authorizeColumns = [
    {
        title: '手机号',
        dataIndex: 'phone',
        key: 'phone'
    },
    {
        title: '用户',
        dataIndex: 'user',
        key: 'user'
    },
    {
        title: '操作',
        width: '220px',
        dataIndex: 'handle',
        scopedSlots: { customRender: 'operation' }
    },
]
const data = [
    {
        name: '测试项目1',
        key: '1',
        desc: '已受理',
        time: '今天'
    },
    {
        name: '测试项目2',
        key: '2',
        desc: '已受理',
        time: '今天'
    },
    {
        name: '测试项目3',
        key: '3',
        desc: '',
        time: '今天'
    },
]
const authorizeData = [
    {
        phone: '13234766767',
        user: '管理员'
    },
    {
        phone: '13134766767',
        user: '管理员'
    },
    {
        phone: '13334766767',
        user: '管理员'
    },
]
export default {
    name: "Index",
    components: {
        Header,
        Map
    },
    data() {
        return {
            // 保存项目概况数据
            dataObj: {
                myProject: 3,
                authorizeProject: 0,
                equipCount: 0
            },
            // 默认显示的标签页
            defaultKey: '1',
            // 创建、编辑项目弹窗是否可见
            isVisible: false,
            title: '创建项目',
            name: '',
            desc: '',
            // 授权管理弹窗是否可见
            visible: false,
            // 创建、编辑项目表单数据
            projectForm: {
                name: '',
                desc: ''
            },
            // 授权项目表单数据
            form: {
                phone: null
            },
            rules: {
                phone: [
                    { required: true, message: '手机号不能为空！' },
                    { validator: phoneCheck, trigger: 'blur' },
                ]
            },
            // 新增授权弹窗是否可见
            visibleAddAuthorize: false,
            // 取消授权弹窗是否可见
            visibleCancelAuthorize: false,
            columns,
            authorizeColumns,
            data,
            authorizeData,
            site: ''
        }
    },
    created() {
        console.log(this.$route.query)
        this.isVisible = this.$route.query.fromPage !== undefined
        this.defaultKey = this.$route.query.setNode !== undefined ? '2' : '1'
        /* getJson().then(res => {
            console.log(res)
        }).catch(err => { console.log(err) }) */
    },
    methods: {
        gotoHome() {
            this.$router.push('/home')
        },
        // 搜索我的项目
        onSearch() { //key
            // let data = {
            //     val: key
            // }
            // getJson(data).then(res => {
            //     console.log(res)
            // }).catch(err => { console.log(err) })
        },
        // 创建、编辑项目
        handleProject(val) {
            let flag = val === 'create'
            this.title = flag ? '创建项目' : '编辑项目'
            if(flag) {
                this.name = ''
                this.desc = ''
            } else {
                this.name = '测试项目'
            }
            this.isVisible = true
        },
        // 点击创建/编辑项目弹窗中的确认按钮
        onCreate() {
            console.log(this.projectForm)
            this.isVisible = false
        },
        // 搜索授权项目
        onSearchAuthorize(){},
        // 进入项目管理
        entryManage(val) {
            console.log(val)
            this.$router.push('/project')
        },
        // 授权管理
        authorizeManage(val) {
            console.log(val)
            this.visible = true
        },
        // 点击授权
        handleAuthorize() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    this.form.phone = ''
                    alert('submit!')
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        // 点击取消授权
        cancelAuthorize(val) {
            console.log(val)
            this.visibleCancelAuthorize = true
        },
        // 确认授权
        handleModal() {
            this.visibleAddAuthorize = false
        },
        // 取消授权
        handleCancelAuthorize() {
            this.visibleCancelAuthorize = false
        },
        // 删除
        onDelete(val) {
            console.log(val)
            this.$confirm({
                title: '删除项目',
                content: '请确认是否删除当前项目',
                okText: '确定',
                centered: true,
                okType: 'danger',
                cancelText: '取消',
                onOk: () => {
                    console.log('OK')
                },
                onCancel() {
                    console.log('Cancel')
                },
            })
        },
        // 获取详细地址信息
        siteInfo(val) {
            this.site = 'X：' + val.point.lat + ' Y：' + val.point.lng + ' ' + val.title
            // console.log(val)
        },
        // 清空地址搜索框
        clearSite() {
            this.site = ''
        }
    }
}
</script>

<style lang="scss" scoped>
    .projectWrap {
        margin: 30px 80px;
        color: #333;
    }
    .projectTop {
        display: flex;
        justify-content: space-between;
    }
    .projectTitle {
        font-size: 20px;
        font-weight: bold;
    }
    .projectStatic {
        display: flex;
        margin-left: 120px;
        li {
            margin-right: 60px;
            text-align: center;
            .projectType {
                font-size: 16px;
                color: #818181;
            }
            .projectNum {
                color: #4D4F5C;
                font-size: 18px;
                font-weight: bold;
            }
        }
    }
    .card-container {
        background: #f5f5f5;
        overflow: hidden;
        padding: 24px;
    }
    .card-container > .ant-tabs-card > .ant-tabs-content {
        height: 120px;
        margin-top: -16px;
    }

    .card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
        background: #fff;
        padding: 16px;
    }

    .card-container > .ant-tabs-card > .ant-tabs-bar {
        border-color: #fff;
    }

    .card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
        border-color: transparent;
        background: transparent;
    }

    .card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
        border-color: #fff;
        background: #fff;
    }
    .search {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between
    }
    .authorizeItem {
        min-height: 200px;
    }
    .authorizeTip {
        margin-top: 80px;
        font-size: 20px;
        color: #333;
        text-align: center;
    }
    .modelTip {
        color: #666;
        font-size: 12px;
    }
</style>
